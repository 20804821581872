import * as React from 'react';
import Layout from '../components/layout/layout';
import * as styles from '../styles/contact.module.css';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Helmet } from "react-helmet"

const ADDITIONAL_SKILLS = [
    "UI",
    "Filmmaking",
    "Videography"
];
const ADDITIONAL_TOOLS = [
    "Unity",
    "C#",
    "Python",
    "Java",
    "C++"
];

const ContactPage = ({ data }) => {
    return (
        <Layout
            title="Sanketh Hegde"
        >
            <div className={styles.contactContainer}>
                <Helmet title="Sanketh Hegde - Contact" />
                <StaticImage src="../images/me.jpeg" alt="Sanketh Hegde"/>
                <div className={styles.contactText}>
                    <p>Contact me at san2heg@gmail.com.</p>
                    <h2>WHAT I DO</h2>
                    <div className={styles.contactLabelContainer}>
                        {data.allMdx.distinctTypes.concat(ADDITIONAL_SKILLS).map(type => 
                            <div className={`${styles.contactLabel} ${styles.contactLabelSkills} heading`}>{type}</div>)}
                    </div>
                    <h2>WHAT I USE</h2>
                    <div className={styles.contactLabelContainer}>
                        {data.allMdx.distinctTools.concat(ADDITIONAL_TOOLS).map(tool => 
                            <div className={`${styles.contactLabel} ${styles.contactLabelTools} heading`}>{tool}</div>)}
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export const query = graphql`
  query {
    allMdx {
      distinctTypes: distinct(field: frontmatter___skillTags)
      distinctTools: distinct(field: frontmatter___toolTags)
    }
  }
`;

export default ContactPage;
import { Link } from 'gatsby';
import * as React from 'react';
import * as styles from './layout.module.css';
import { Helmet } from "react-helmet"

import igLogo from "../../images/instagram.svg";
import emailLogo from "../../images/email.svg";
import favicon from "../../images/favicon.ico";

const Layout = ({ title, subtitle, children, navLeft, navRight }) => {
    return (
        <div>
            <Helmet>
                <link rel="icon" href={favicon} />
            </Helmet>
            <div className={styles.contentContainer}>
                <div className={styles.nav}>
                    <div className={styles.navLeft + " title"}>
                        {navLeft ? navLeft : <Link to="/">❮ Work</Link>}
                    </div>
                    <div className={styles.navMiddle + " title"}>
                        <div className={styles.title}>{title}</div>
                        {subtitle && <div className={styles.subtitle + " paragraph"}>{subtitle}</div>}
                    </div>
                    <div className={styles.navRight + " title"}>
                        {navRight ? navRight : <Link to="/contact">Contact</Link>}
                    </div>
                </div>
                <div className={styles.contentBody}>
                    {children}
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerLabel}>
                    <div className={styles.socialContainer}>
                        <a className={styles.socialRow + " paragraph"} href="https://www.instagram.com/san2heg/">
                            {/* <img className={styles.socialLogo} src={igLogo} alt="instagram" /> */}
                            instagram: san2heg
                        </a>
                        <a style={{paddingLeft: "20px"}} className={styles.socialRow + " paragraph"} href="mailto:san2heg@gmail.com">
                            {/* <img className={styles.socialLogo} src={emailLogo} alt="email" /> */}
                            email: san2heg@gmail.com
                        </a>
                    </div>
                    <div>© Sanketh Hegde 2024</div>
                </div>
            </div>
        </div>
    )
}

export default Layout;
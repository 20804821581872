// extracted by mini-css-extract-plugin
export var contentContainer = "layout-module--contentContainer--PSp2Z";
export var nav = "layout-module--nav--Cphws";
export var navLeft = "layout-module--nav-left--elLxW";
export var navRight = "layout-module--nav-right--7dtSH";
export var navMiddle = "layout-module--nav-middle--QR-NT";
export var title = "layout-module--title--HfgaC";
export var subtitle = "layout-module--subtitle--woCHG";
export var contentBody = "layout-module--content-body--e0tLg";
export var footer = "layout-module--footer--+DZDb";
export var footerLabel = "layout-module--footer-label--ti8G1";
export var socialContainer = "layout-module--social-container--i4cel";
export var socialRow = "layout-module--social-row--MHPYd";
export var socialRowHeader = "layout-module--social-row-header--1wqkT";
export var socialLogo = "layout-module--social-logo--1HuCa";